@import 'variables.scss';

$textColor: #2d2d2d;
$textColorLight: lighten($textColor, 20%);
$textLogoBlue: #F1BF6D;
$textLogoOrange: #54a7de;

@font-face {
  font-family: "Gotham-Rounded";
  src: url("../fonts/Gotham-Rounded-Light.ttf")
}

@font-face {
  font-family: "Gotham-Rounded-Medium";
  src: url("../fonts/Gotham-Rounded-Medium.ttf")
}

@font-face {
  font-family: "Gotham-Rounded-Bold";
  src: url("../fonts/Gotham-Rounded-Bold.ttf")
}

html {
  font-family: "Lato", "Helvetica Rounded", Arial, sans-serif;
  color: $textColor;
  line-height: 1.28;
}

p {
  margin: 0 0 10px;
}

.subHeader {
  font-size: 21px;
  font-weight: 200;
  line-height: 30px;
  margin-bottom: 10px;
}

em {
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  font-family: inherit;
  font-weight: bold;
  line-height: 20px;
  color: inherit;
  text-rendering: optimizelegibility;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: normal;
  color: $textColorLight
}

h1,
h2,
h3 {
  line-height: 40px;
}

h1 {
  font-size: 39px;
}

h2 {
  font-size: 31px;
}

h3 {
  font-size: 23px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 11px;
}

h1 small {
  font-size: 24px;
}

h2 small {
  font-size: 18px;
}

h3 small {
  font-size: 16px;
}

h4 small {
  font-size: 14px;
}

ul,
ol {
  margin: 0 0 10px 25px;
  padding: 0;
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}

li {
  line-height: 20px;
}

a {
  color: $linkColor;
  text-decoration: none;
  &:hover,
  &:focus {
    color: $linkInteract;
    text-decoration: underline;
  }
  &:focus {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
}
.center {
  text-align: center;
}
