$primary: #db2975;
$linkColor: darken($primary, 2%);
$linkInteract: darken($linkColor, 9%);
$pageBg: #ffffff;

$lightColor: #e9e9e9;
$mediumestColor: #666;
$mediumColor: #484848;
$darkColor: #2d2d2d;
$darkerColor: #17596E;
$darkestColor: #0D3F60;
$blueColor: #61dafb;
$orangeColor: complement($blueColor);

$lightTextColor: #fafafa;
$mediumTextColor: #aaa;
$darkTextColor: $mediumColor;

$buttonBlueTop: #77a3d2;
$buttonBlueBottom: #4783c2;
$buttonGreyTop: #9a9a9a;
$buttonGreyBottom: #646464;
