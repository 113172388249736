// @import '_variables';
@import "_typography";
// @import '_solarized';

// BEGIN HEADER STYLES
$light-trans-grey: rgba(203, 203, 203, 0.32);
$small-devices: 768px;
$medium-devices: 992px;
$large-devices: 1200px;
$mobile-header-color-black: #333333;
$mobile-pillar-b-background: #efefef;

html {
  -webkit-font-smoothing: antialiased;
}
body {
  font-family: "Gotham-Rounded", "Helvetica Neue", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
}
html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

h1 {
  font-family: "Gotham-Rounded-Medium";
}

#top-header {
  border-bottom: 1px solid $light-trans-grey;
  background-color: #00294b;
  background-size: 100% auto;
}
.electrode-nav-top {
  padding-bottom: 20px;
  font-weight: 500;
  font-size: 1.145em;
  letter-spacing: 1px;
  @media (max-width: 768px) {
    padding-bottom: 0;
  }
  .header-logo {
    height: 50px;
    @media (max-width: 1200px) {
      width: 90%;
    }
    @media (max-width: 1120px) {
      width: 80%;
    }
    @media (max-width: 1000px) {
      width: 190px;
      height: auto;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
    @media (max-width: 380px) {
      width: 80%;
    }
  }
}
.navbar {
  margin: 0 !important;
}

.inverse-colors {
  color: white;
  a,
  p,
  h1,
  h2,
  h3,
  h4 {
    color: white;
  }
}

.night-sky {
  padding: 0;
  margin: 0;
  background-color: #00294b;
}

.social-nav-links {
  li {
    border-radius: 4px;
    margin-top: -4px;
    margin-right: 1em;
    height: 79px;
    @media (max-width: 820px) {
      margin-right: 0.5em;
    }
    &:hover {
      a {
        img {
          transform: scale(1.2);
        }
      }
    }
    a {
      padding: 34px 12px 18px 12px;
      &:hover {
        background-color: inherit;
      }
      @media (max-width: 820px) {
        padding: 34px 8px 18px 8px;
      }
      img {
        width: 24px;
        height: 22px;
      }
    }
  }
}

.header-mountains img {
  width: 100%;
  margin-top: 1em;
  margin-bottom: -2px;
}

.pillars-section-2 {
  .pillar-grey-border {
    border-right: 1px solid rgba(128, 128, 128, 0.25);
  }

  .pillar-icon {
    text-align: center;
    img {
      width: 75%;
      margin-top: -17vw;
    }
  }

  .pillar-content {
    margin-top: 2.5em;
    h1 {
      font-family: "Gotham-Rounded-Medium";
      font-weight: 300;
    }
    p {
      font-family: "Gotham-Rounded";
      font-weight: 600;
      font-size: 1.1em;
    }
  }
}

.header-content {
  font-family: "Gotham-Rounded";
  background-position: center 27px;
  background-image: url("/img/sky.svg");
  background-size: 100%;
  background-repeat: no-repeat;

  .logo-text {
    margin-top: 23px;
    padding: 0;
    padding-bottom: 38px;
    img {
      width: 100%;
    }
    @media (max-width: 768px) {
      padding-bottom: 37px;
    }
  }
  .slogan {
    font-size: 1.7em;
    font-weight: 200;
    letter-spacing: 0.3vw;
    border-top: 1px solid rgba(203, 203, 203, 0.32);
    padding-top: 1.1em;
    margin-top: -0.6em;
    text-align: center;
    font-family: "Gotham-Rounded-Medium";
    padding-left: 0;
    padding-right: 0;
    @media (max-width: 1190px) {
      font-size: 1.7em;
      letter-spacing: 0.2vw;
    }
    @media (max-width: 1110px) {
      font-size: x-large;
      letter-spacing: 0;
    }
    @media (max-width: 980px) {
      font-size: 1.6em;
    }
    @media (max-width: 915px) {
      font-size: 1.4em;
    }
    @media (max-width: 805px) {
      font-size: 1.3em;
    }
    @media (max-width: 768px) {
      font-size: 1.35em;
      padding-bottom: 1.1em;
    }
  }
  .header-links {
    margin-bottom: 2.5em;
  }
  .header-info {
    font-size: 1.2em;
    font-weight: 600;
    margin-top: 0.9em;
    padding-left: 0;
    padding-bottom: 1.5em;
    padding-right: 0;
    text-align: center;
    border-bottom: 1px solid $light-trans-grey;
  }

  .header-button {
    font-family: "Gotham-Rounded-Bold";
    margin-top: 2em;
    font-weight: 500;
    font-size: 1.5em;
    padding-top: 0.5em;
    padding-bottom: 0.2em;
    text-align: center;
    border-radius: 3px;
    width: 100%;
    @media (max-width: 847px) {
      font-size: 1.3em;
    }
  }
  .get-started {
    background-color: #df2375;
    border: 3.5px solid rgb(223, 35, 117);
    transition: all 0.2s ease-in-out;
  }
  .get-started:hover {
    transition: all 0.5s ease;
    background-color: #ff0066;
    border: 3.5px solid #ff0066;
  }
  .about-electrode {
    background-color: rgba(255, 255, 255, 0);
    border: 3.5px solid white;
    transition: all 0.2s ease-in-out;
  }
  .about-electrode:hover {
    transition: all 0.5s ease;
    color: #fff;
    border: 3.5px solid #ff0066;
  }
  .visible-xs-block .get-started {
    width: 100%;
  }
}

@media (max-width: 1279px) {
  .header-info {
    padding: 0%;
  }
}

.social-nav-links li:nth-child(2) {
  // margin-left: .5em;
  background-color: #eb3d4e;
}
.social-nav-links li:nth-child(3) {
  background-color: #1686c0;
}
.social-nav-links li:nth-child(4) {
  background-color: white;
}

.primary-nav-links {
  li {
    a {
      font-family: "Gotham-Rounded-Bold";
      padding: 41px 97.5px 0 0;
      @media (max-width: 1200px) {
        padding: 45px 70px 0 0;
      }
      @media (max-width: 1120px) {
        padding: 45px 60px 0 0;
      }
      @media (max-width: 1000px) {
        padding: 45px 50px 0 0;
        font-size: 1em;
      }
      @media (max-width: 900px) {
        padding: 45px 40px 0 0;
        font-size: 0.9em;
      }
      @media (max-width: 820px) {
      }
      @media (max-width: 700px) {
      }
      &:hover {
        background-color: inherit;
      }
    }
  }
  #contribute a {
    padding-right: 0;
  }
  #home-primary:before {
    content: "";
    background-color: white;
    width: 95px;
    height: 8px;
    margin-bottom: -8px;
    display: block;
    margin-left: -21.5px;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .contribute-active-margin:before {
    margin-left: 9.5px !important;
  }
}

.navbar-toggle {
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.mob-menu {
  height: 65px;
  width: auto;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-top: -29px;
  margin-right: 0.5em;
}

#mob-menu-button,
img.mob-menu {
  height: 65px;
  width: auto;
  border-radius: 4px;
  margin-right: 10px;

  @media (max-width: 380px) {
  }
}

@media (max-width: 1120px) {
}

@media (max-width: 800px) {
}

.electrode-brand-top {
  padding-top: 30px;
  @media (max-width: 1200px) {
    padding-top: 29px;
  }
  @media (max-width: 1000px) {
    padding-top: 34px;
  }
  @media (max-width: 768px) {
    padding-top: 10px;
  }
}

.vert-divider {
  position: absolute;
  width: 0px;
  height: 100%;
  border-left: 1px solid $light-trans-grey;
  margin-left: -22px;
}
//END HEADER STYLES

//PILLAR STYLES
.pillar-content {
  h1 {
    text-align: center;
  }

  p {
    margin: 20px 0 40px;
  }
}

.pillar-links {
  text-align: center;
  a {
    border-radius: 8px;
    border: 1px solid #d62470;
    background-color: #df2375;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    padding: 10px;
  }
  a:hover {
    transition: all 0.5s ease;
    background-color: #ff0066;
    border: 1px solid #ff0066;
    text-decoration: none;
  }
}

//BEGIN CORE STYLES

@media only screen and (max-width: 1656px) {
  .electrode-core {
    #core-h3 {
      padding: 0% 10% 0% 10% !important;
    }

    #electrode-core-text {
      div {
        padding-bottom: 3%;
      }
    }
  }
}

.electrode-core {
  border-bottom: 1px #ececec solid;
  text-align: center;
  font-family: "Gotham-Rounded";
  padding: 50px 0px 50px 0px;

  h1 {
    font-family: "Gotham-Rounded-Medium";
  }

  #core-h3 {
    padding: 0% 25% 0% 25%;
  }

  div {
    padding: 10px 0px 10px 0px;
  }

  h4 {
    font-family: "Gotham-Rounded-Bold";
  }

  #electrode-core-text {
    text-align: left;
    padding-right: 10%;
    font-size: 120%;

    div {
      padding-bottom: 7%;
    }
  }

  #electrode-header {
    background: linear-gradient(#00203b, #032a4a);
  }
  .top-core-content {
    padding: 60px 0px 10px 0px;
    border-top: 1px solid rgba(128, 128, 128, 0.25);
  }
}

#snippet-bubble {
  float: right;
  font-weight: bold;
  border-radius: 4px;
  border: solid 1px #cccccc;
  text-align: left;
  top: 0px;
  width: 100%;
  height: 450px;
  z-index: 2;

  .snippet-bubble-text {
    font-size: 16px;
    padding: 10px 0;
    text-align: left;
  }

  h3 {
    text-align: center;
    padding: 0px;
  }

  h4 {
    font-size: 26px;
    font-weight: normal;
  }

  #deploy-msg {
    text-align: left;
  }

  #details-btn {
    color: #141e26;
    background: inherit;
    border: solid 2px #141e26;
    font-family: "Gotham-Rounded-Bold";
  }

  #deploy-btn {
    font-family: "Gotham-Rounded-Bold";
    background: $primary;
    color: white;
  }
}

#code-snippet {
  background-color: #141e26;
  color: white;
  padding: 2% 0% 2% 0%;
  text-align: left;

  p {
    padding-left: 5%;
    font-size: 110%;
  }
}

.grayscale {
  filter: grayscale(100%);
  width: 8%;
  height: auto;
  display: block;
  margin: auto;
}

//END CORE STYLES

//BEGIN MODULES STYLES
.landing-logo {
  width: 125px;
  height: 125px;
  margin: -10px -20px 7px -20px;
}

#modules-section {
  padding: 20px 60px 80px 100px;
  border-bottom: 1px solid #cccccc;
}

.electrode-modules,
#modules-section {
  font-family: "Gotham-Rounded";
  text-align: center;

  .icon-modules {
    height: 104px;
    margin-bottom: 46px;
    width: 116px;
  }

  h1 {
    font-size: 56px;
    font-weight: normal;
    margin-top: 0;
  }

  h3 {
    font-size: 26px;
    font-weight: normal;
  }

  .desktop-module-item {
    text-align: left;

    .module-subheader {
      font-size: 20px;
      margin-top: 40px;
      font-family: "Gotham-Rounded-Medium";
    }

    .desktop-module-logo img {
      width: 66px;
      float: right;
      height: 75px;
      margin-right: 20px;
    }

    .module-testbox {
      font-size: 18px;
      min-height: 240px;
      padding-left: 30px;
      margin-right: 40px;
      margin-top: 0px;
    }
  }

  .electrode-modules-list {
    list-style: none;
    margin-top: 100px;
    padding-bottom: 400px;
    text-align: left;

    .module-title {
      font-weight: bold;
    }

    .icon-module {
      filter: grayscale(100%);
      margin: 60px 0 0 20px;
      width: 80px;
    }

    li {
      border-bottom: 1px #ececec solid;
      padding-bottom: 20px;
    }
  }

  .electrode-modules-background {
    background-image: url("/img/module_laptop_graphic_@1x.png");
    background-size: 95%;
    background-repeat: no-repeat;
    background-position: bottom left;
  }

  .electrode-modules-image {
    float: left;

    img {
      width: 400px;
    }
  }
}

@media (max-width: 1548px) {
  .electrode-modules,
  #modules-section {
    .desktop-module-item {
      .module-testbox {
        min-height: 270px;
      }
    }
  }
}

@media (max-width: 1351px) {
  #modules-section {
    padding: 20px 0 80px 40px;
  }

  .electrode-modules,
  #modules-section {
    .desktop-module-item {
      .module-testbox {
        min-height: 340px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .electrode-modules,
  #modules-section {
    .desktop-module-item {
      .module-testbox {
        min-height: 420px;
      }
    }
  }
}

@media (max-width: 900px) {
  .electrode-modules,
  #modules-section {
    .desktop-module-item {
      .module-testbox {
        min-height: 500px;
      }
    }
  }
}

.row {
  margin: 0px;
}
//BEGIN TOOLS STYLES
.electrode-tools {
  margin-top: 70px;
  text-align: center;

  h1 {
    font-size: 56px;
  }

  h3 {
    font-size: 26px;
    font-weight: normal;
  }

  .icon-modules {
    height: 104px;
    width: 116px;
    margin-bottom: 46px;
  }

  /* Since positioning the image, we need to help out the caption */
  .carousel-caption {
    z-index: 10;
  }

  /* Declare heights because of positioning of img element */
  .carousel .item {
    height: 1150px;
    background-image: url("/img/tools_landscape_@1x.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: 550px;

    .tool-title {
      margin-top: 40px;
      border-top: 1px #ececec solid;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 80px;

      position: relative;
      text-align: left;

      h3,
      p {
        padding-left: 0;
        margin-left: 0;
      }

      h3 {
        margin-top: 21px;
      }

      .github-logo {
        margin-right: 0;
        padding-right: 0;
        float: right;

        img {
          width: 48px;
          height: 72px;
        }
      }
    }

    .image {
      width: 90%;
      height: 90%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-position-y: 200px;
      margin-left: auto;
      margin-right: auto;
    }

    .image-electrode-explorer {
      background-image: url("/img/explorer_laptop_graphic_@1x.png");
    }

    .image-electrode-electrify {
      background-image: url("/img/bundle_laptop_graphic_@1x.png");
    }
  }

  .carousel-control {
    width: 0;

    span {
      position: absolute;
      top: 35%;
      background-color: #cccccc;
      background-image: url("/img/arrow_@1x.png");
      background-size: 50% 50%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      width: 70px;
      height: 70px;
      border-radius: 70px;
    }

    span:hover {
      background-color: #df2375;
    }

    .button-previous {
      left: 40px;
    }

    .button-next {
      right: 40px;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  .carousel-control.left,
  .carousel-control.right {
    background-image: none;
  }
}
//END TOOLS STYLES

// BEGIN FOOTER STYLES

.container-fluid {
  padding: 0px;
}

#footer-nav-left {
  .navbar-brand {
    margin-right: 3%;
    margin-bottom: 11px;
    padding: 0;
    height: auto;
  }
}

.footer {
  background-color: #1d1d1d !important;
  .footer-text {
    float: left;
    font-size: 14px;
    color: #fff;
    margin-bottom: 0;
    padding-bottom: 0;
    font-family: "Gotham-Rounded";
  }
}

#footer-walmart-logo {
  width: 70%;
  height: auto;
}

.footer-sub-logo {
  width: 20%;
  height: auto;
}

#footer-nav {
  display: inline-block;
}

a.footer-text {
  color: #c4c4c4;
  font-family: "Gotham-Rounded";
  font-weight: bold;
  font-size: 20px;
}

.footer-sub-logo {
}

#doc-logos {
  width: 400px;
}

#electrode-footer {
  padding: 35px 0 35px 20px;
  margin: 0;
}

#footer-nav-center {
  float: right;
}

.social-logo {
  padding-right: 20px;
  padding-right: 20px;
}

.footer-sub-logo {
  height: 50px;
  width: auto;
}

.footer-text.bottom {
  margin-bottom: -5px;
}

.pocket-logo {
  img {
    width: 1.3em;
  }
}

.twitter-logo {
  img {
    width: 1.3em;
  }
}

.github-logo {
  img {
    width: 1.3em;
  }
}

div.footer-row {
  margin: 0px;
}

.footer-row-center {
  margin-top: 24px;
  float: right;
}

div.footer-docs {
  padding-top: 20px;

  .footer-doc-links {
    color: #fff;
    font-size: 14px;
    font-family: "Gotham-Rounded";

    #doc-link-right {
      margin-left: -10px;
    }
  }
}

div.footer-vertical-line {
  margin-top: -20px;
  height: 100px;
  width: 0px;
  position: relative;
  border-left: 1px solid #2c2c2c;
}

img.footer-sub-logo {
  filter: invert(1);
  margin-top: 5px;
}

#second-nav {
  background: #fff;
  border-bottom: solid 1px #cccccc;
  border-top: solid 1px #cccccc;

  .row {
    margin-top: -2px;
  }

  .second-nav-row {
    padding-top: 15px;
  }

  #second-nav-hr {
    border-bottom: solid 1px #cccccc;
  }

  .second-nav-top-pad {
    padding-top: 20px;
  }

  .second-nav-top-pad-double {
    padding-top: 40px;
  }

  .second-nav-links {
    font-weight: bold;
    .dark-links {
      color: #333;
    }
  }

  .inner-links-ul {
    padding-right: 50px;
  }

  .outer-links-ul {
    padding-right: 20px;
  }

  .second-nav-links-container {
    float: right;
    padding-right: 3.2%;
  }

  #get-started {
    background: $primary;
    color: white !important;
    border-radius: 3px;
    border-top: solid 1px white;

    &.focus {
      background: $primary;
    }
  }

  #electrode-brand-header {
    width: 50%;
    height: auto;
    margin-top: -10px;
  }

  #second-nav-social-img {
    border-top: solid 1px white;
    padding-left: 20px;
  }

  .second-nav-inner-links {
    padding: 0px 10px 10px 0px;

    img {
      width: 75%;
      display: block;
      margin: auto;
    }
  }

  .active-inner-link {
    border-top: solid 8px #333333;
    img {
      margin-top: -7px;
    }
  }

  .inactive-inner-link {
    border-top: solid 1px #fff;
  }
}

// Global helper classes

.vertical-align-this {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.horizontal-align-this {
  position: relative;
  right: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

//START MOBILE VIEW

.pillars-section-2-mobile {
  width: 100%;

  #pillars-mobile-container {
    text-align: center;
    min-width: 320px;
    font-family: "Gotham-Rounded";

    .add-padding-right {
      padding-right: 7%;
    }

    .add-padding-left {
      padding-left: 6.5%;
    }

    .mobile-header-items {
      color: $mobile-header-color-black;
      padding: 10px 0;
      margin-left: 2%;
      margin-right: 2%;
      cursor: pointer;
      font-size: 18px;
      font-family: "Gotham-Rounded";
      font-weight: 600;
    }

    .selected {
      padding-bottom: 6px !important;
      border-bottom: 4px solid #38bbff;
    }

    #mobile-core-content {
      .mobile-content-heading {
        font-weight: 600;
        font-size: 15px;
      }
    }
  }
}

#mobile-pillar-content-zone {
  padding: 12px 5%;
  line-height: 1.4;
  font-size: 14px;
  font-family: "Gotham-Rounded";

  #mobile-core-content {
    .mobile-content-heading {
      font-weight: 600;
    }
  }
}

.mobile-pillar-bar {
  width: 100%;
  height: 4px;
  margin-top: -4px;
  margin-bottom: 10px;
  background-color: $mobile-pillar-b-background;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.2);
}

.mobile-footer-toggle {
  border-bottom: 1px solid #333;
  height: 62px;
  min-width: 320px;
  margin-top: 30px;

  .footer-toggle-items {
    float: left;
    margin: 10px 0;
    margin-left: 5%;
    font-size: 34px;
    cursor: pointer;
  }

  .mobile-footer-getStarted-btn {
    font-family: "Gotham-Rounded-Bold";
    background: $primary;
    border: none;
    margin-right: 5%;
    border-radius: 3px;
    height: 34px;
    width: 42%;
    text-align: center;
    color: white;
    float: right;
    margin-top: 12px;
  }
}

.mobile-footer {
  font-family: "Gotham-Rounded";
  font-size: 10px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  background-color: #00294b;
  color: #ffffff;
  height: 50px;
  min-width: 320px;
  position: absolute;
  width: 100%;
  padding: 0 5%;
  margin-top: 10px;
}

.mobile-module-item {
  padding: 20px;
  border-bottom: 1px solid #cccccc;

  .mobile-module-item-text {
    width: 80%;
    display: inline-block;
    padding: 20px 0;
  }
}

@media (max-width: 767px) {
  .pillars-section-2,
  .electrode-core,
  .electrode-tools,
  .electrode-modules,
  #desktop-nav-ul,
  #second-nav,
  #electrode-modules,
  #modules-section,
  #electrode-footer,
  #social-header-color-links {
    display: none;
  }

  #electrode-nav,
  #electrode-mobile-nav {
    margin-top: -17px;
  }

  #snippet-bubble {
    height: auto;
    margin-bottom: 12px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 12px;

    h4 {
      padding-top: 5px;
      font-size: 18px;
      font-family: Gotham-Rounded-Medium;
    }

    padding-left: 20px;
    padding-right: 20px;

    h4 {
      font-size: 18px;
    }

    .snippet-bubble-text {
      font-size: 15px;
      margin-top: 10px;
      text-align: left;
      margin-bottom: 10px;
      padding: 0;
    }
  }

  #code-snippet {
    font-size: 12px;
    width: 100%;
    padding: 2% 0 0 0;

    h4 {
      font-size: 18px;
    }
  }

  .header-info {
    display: none;
  }

  .slogan {
    border-bottom: 1px solid $light-trans-grey;
  }

  .header-button {
    #deploy-in-five {
      display: inline;
    }
  }

  #about-electrode {
    display: none;
  }

  .header-mountains {
    img {
      padding-top: 75px;
    }
  }
}

@media (min-width: 768px) {
  .pillars-section-2-mobile,
  .mobile-footer-toggle,
  .mobile-footer,
  .mobile-pillar-bar,
  li.mobile-header-links,
  ol.mobile-header-links,
  #mob-header-text-container,
  #mob-header-text,
  #mobile-nav-ul,
  #mobile-pillar-content-zone,
  #mobile-social-link-row,
  #mobile-started-link {
    display: none;
  }

  .social-nav-links {
    padding-right: 5px;
  }

  #electrode-core-text {
    div {
      padding-bottom: 3%;
    }
  }
}

.navbar .divider-vertical {
  height: 145px;
  margin: 0 9px;
  border-right: 1px solid #cccccc;
  border-left: 1px solid #f2f2f2;
  border-top: 8px solid #ffffff;
}

.navbar-inverse .divider-vertical {
  border-right-color: #222222;
  border-left-color: #111111;
}

#mobile-tools-content {
  font-family: "Gotham-Rounded";
  min-width: 320px;
  /* Since positioning the image, we need to help out the caption */
  .carousel-caption {
    z-index: 10;
  }

  /* Declare heights because of positioning of img element */
  .carousel .item {
    height: auto;

    .inside-car-title {
      padding-left: 10px;
    }

    .tool-title {
      font-family: "Gotham-Rounded";
      margin-top: 40px;
      border-top: 1px #cccccc solid;
      margin-bottom: 50px;
      position: relative;
      text-align: left;
      border-bottom: 1px solid #cccccc;

      .github-logo {
        float: right;
        width: 34px;
        height: 40px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }

    .image {
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .carousel-control {
    span {
      position: absolute;
      top: 310px;
      background-color: #df2375;
      background-image: url("/img/arrow_@1x.png");
      background-size: 50% 50%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      width: 35px;
      height: 35px;
      border-radius: 35px;

      &.hover {
        background-color: #df2375;
      }
    }

    .button-previous {
      left: 0;
    }

    .button-next {
      right: 0;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
      &.hover {
        background-color: #df2375;
      }
    }
  }

  .carousel-control.left,
  .carousel-control.right {
    background-image: none;
  }
}

@media (max-width: 767px) {
  #header-intro-paragraph,
  #desktop-modules-content,
  .navbar-collapse .nav > .divider-vertical {
    display: none;
  }

  img.header-logo {
    height: 120%;
    margin-top: 0;
  }

  div#mobile-logo-text {
  }
  .header-mountains {
    img {
      padding-top: 0;
    }
  }
}

@media (max-width: 680px) {
}

@media (max-width: 480px) {
  div#mobile-slogan {
    font-size: 21px;
  }
  .header-content .get-started {
    font-size: 14px;
    font-weight: bold;
  }
  .header-content .header-button {
    width: 100%;
  }
  .header-mountains {
    img {
      padding-top: 0;
    }
  }
}

@media (max-width: 380px) {
  img.header-logo {
    height: 80%;
  }

  #mobile-slogan {
    font-size: 18px;
  }

  .mobile-footer-text-container {
    font-size: 8px;
  }
}

@media (max-width: 325px) {
  .mobile-footer-text-container {
    font-size: 7px;
  }
}

.mobile-social-links {
  height: 20px;
  width: auto;
}

.mobile-git {
  height: 30px;
  width: auto;
}

.mobile-internal-links {
  padding: 10px 0;
}

@media (min-width: 769px) {
  .container {
    width: 97%;
  }
}

.module-testbox {
  margin-top: 10px;
  margin-left: -30px;
  margin-right: 20px;
  margin-bottom: 20px;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.desktop-module-text {
  margin: 20px -50px 20px 50;
}

#module-icon {
  filter: grayscale(100%);
  height: 100px;
  width: 100px;
}

#desktop-modules-header {
  text-align: center;
  margin: 50px 20px;
}

.module-subheader {
  margin-top: 20px;
  margin-bottom: -20px;
}

.desktop-module-logo {
  margin-top: 20px;
}

.module-textbox {
  margin-bottom: 40px;
  padding-right: 60px;
}

#mob-menu-button,
.mobile-header-links,
.mobile-internal-links {
  cursor: pointer;
}

#mobile-header-info,
.mobile-header-items {
  font-weight: bold;
}

//Mobile header styles

@media (max-width: 767px) {
  #mobile-social-link-row {
    padding: 40px 0 10px 0;

    .mobile-social-logo:hover {
      color: #df2375;
    }
  }

  #walmart-header-logo {
    margin: 20px 0 0 10px;
    height: 160%;
    width: auto;
    @media (max-width: 320px) {
      height: 150%;
    }
  }

  .mobile-oval {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    text-align: center;
    border: solid 1px rgba(255, 255, 255, 0.2);

    &:hover {
      transform: scale(1.2);
    }
  }

  .mobile-header-line {
    width: 90%;
    height: 1px;
    margin: 10px 0 10px 20px;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .mobile-social-logo {
    object-fit: contain;
    margin-bottom: -23px;
  }

  .mobile-social-header-links {
    margin: 0 auto;
  }

  #mob-header-text-container {
    text-align: center;
    margin-bottom: 30px;

    a:hover {
      cursor: pointer;
    }
  }

  #mobile-dropDown-logo {
    height: 25px;
    width: auto;
  }

  #mobile-header-text {
    padding-top: 10px;
    font-size: 18px;
  }

  #mobile-nav-ul {
    font-size: 18px;
    li {
      a {
        font-size: 18px;
        font-weight: 600;
        font-family: "Gotham-Rounded";
        margin-left: 20px;
        line-height: 2;

        &:hover {
          color: #df2375;
          background-color: #000e23;
        }
      }
    }
  }
}

// ============================================================

// Electrode Mobile/Web Landing Page

// ============================================================

.intro-page {
  padding-top: 15px;
  &__section {
    position: relative;
    padding-left: 4%;
    &--left-border {
      border-left: 1px solid rgba(128, 128, 128, 0.25);
    }
  }
  &__content {
    &--bottom-border {
      @media (max-width: 767px) {
        border-bottom: 1px solid rgba(128, 128, 128, 0.25);
      }
    }
    &--top-padding {
      @media (max-width: 767px) {
        padding-top: 30px;
      }
    }
    p {
      font-size: 1.2em;
      line-height: 1.5;
      font-weight: 600;
      padding-right: 10%;
      padding-top: 10px;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 25px;
      }
    }
    h1 {
      font-size: 3.6em;
      font-weight: 300;
      line-height: 1.5em;
      @media (max-width: 992px) {
        padding-left: 25%;
        font-size: 2.75em;
      }
      @media (max-width: 767px) {
        padding-left: 10%;
        font-size: 24px;
        font-weight: 600;
      }
      @media (max-width: 650px) {
        padding-left: 12%;
      }
      @media (max-width: 520px) {
        padding-left: 15%;
      }
      @media (max-width: 430px) {
        padding-left: 17%;
      }
    }
  }
  &__flag {
    position: absolute;
    right: 30px;
    @media (max-width: 767px) {
      height: 70px;
      width: 70px;
    }
  }
  &__img {
    &--top-margin {
      @media (max-width: 767px) {
        margin-top: 30px;
      }
    }
    position: absolute;
    float: left;
    margin-left: 15px;
    width: 90px;
    height: 102px;
    @media (max-width: 992px) {
      width: 68px;
      height: 77px;
    }
    @media (max-width: 767px) {
      width: 48px;
      height: 52px;
    }
  }
  &__button {
    &:hover {
      color: #ff0066;
      border: 2px solid #ff0066;
      transition: all 0.2s ease-in-out;
    }
    width: 228px;
    height: 46px;
    border: 2px solid black;
    background-color: transparent;
    text-align: center;
    border-radius: 4px;
    font-size: 1.2em;
    box-sizing: border-box;
    font-family: "Gotham-Rounded-Medium";
    line-height: 43px;
    margin-top: 30px;
    color: black;
    @media (max-width: 768px) {
      margin: 15px 0 30px 0;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      width: 136px;
      height: 34px;
      line-height: 29px;
    }
  }
  &__button-div-mobile {
    display: none;
    @media (max-width: 767px) {
      text-align: center;
      display: block;
    }
  }
  &__content-link {
    padding-left: 4%;
    &--left-border {
      border-left: 1px solid rgba(128, 128, 128, 0.25);
    }
  }
  &__button-toggle {
    @media (max-width: 767px) {
      display: none;
    }
  }
  &__bottom-margin {
    margin-bottom: 60px;
  }
  &__button-div,
  &__content {
    padding-left: 4%;
    @media (min-width: 1400px) {
      padding-left: 1%;
    }
    @media (max-width: 1170px) {
      padding-left: 7%;
    }
    @media (max-width: 992px) {
      padding-left: 0;
    }
  }
}

// ============================================================

// Electrode React Native Page

// ============================================================

// header styles
#ern-header {
  background: url("/../img/mobile-page/ern_bkgd_nav.png") no-repeat center;
  background-size: cover;
}
.star-sky {
  background: url("/../img/mobile-page/ern_bkgd.png") no-repeat center;
  background-size: cover;
}
.bottom-border {
  border-bottom: 1px solid rgba(203, 203, 203, 0.32);
}
.header-mobile-content {
  font-family: "Gotham-Rounded";
  .logo-text {
    margin-top: 23px;
    padding: 0;
    padding-bottom: 38px;
    img {
      width: 100%;
    }
    @media (max-width: 768px) {
      padding-bottom: 37px;
    }
  }
  .slogan {
    font-size: 1.7em;
    font-weight: 200;
    letter-spacing: 0.3vw;
    border-top: 1px solid rgba(203, 203, 203, 0.32);
    padding-top: 1.1em;
    margin-top: -0.6em;
    text-align: center;
    font-family: "Gotham-Rounded-Medium";
    padding-left: 0;
    padding-right: 0;
    @media (max-width: 1190px) {
      font-size: 1.7em;
      letter-spacing: 0.2vw;
    }
    @media (max-width: 1110px) {
      font-size: x-large;
      letter-spacing: 0;
    }
    @media (max-width: 980px) {
      font-size: 1.6em;
    }
    @media (max-width: 915px) {
      font-size: 1.4em;
    }
    @media (max-width: 805px) {
      font-size: 1.3em;
    }
    @media (max-width: 768px) {
      font-size: 1.35em;
      padding-bottom: 1.1em;
    }
  }
  // .header-links {
  //   margin-bottom: 2em;
  // }
  .header-info {
    font-size: 1.2em;
    font-weight: 600;
    margin-top: 0.9em;
    padding-left: 0;
    padding-bottom: 1.5em;
    padding-right: 0;
    text-align: center;
    border-bottom: 1px solid $light-trans-grey;
  }

  .header-button {
    font-family: "Gotham-Rounded-Bold";
    margin-top: 2em;
    font-weight: 500;
    font-size: 1.5em;
    padding-top: 0.5em;
    padding-bottom: 0.2em;
    text-align: center;
    border-radius: 3px;
    width: 100%;
    @media (max-width: 1119px) {
      font-size: 1.3em;
    }
    @media (max-width: 995px) {
      font-size: 14px;
    }
    @media (max-width: 820px) {
      font-size: 12px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
    }
    @media (max-width: 480px) {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .header-button-info {
    font-size: 0.8em;
  }
  .get-started {
    background-color: #df2375;
    border: 3.5px solid rgb(223, 35, 117);
    transition: all 0.2s ease-in-out;
  }
  .get-started:hover {
    transition: all 0.5s ease;
    background-color: #ff0066;
    border: 3.5px solid #ff0066;
  }
  .about-electrode {
    background-color: rgba(255, 255, 255, 0);
    border: 3.5px solid white;
    transition: all 0.2s ease-in-out;
  }
  .about-electrode:hover {
    transition: all 0.5s ease;
    color: #fff;
    border: 3.5px solid #ff0066;
  }
  .visible-xs-block .get-started {
    width: 100%;
  }
  .header-clouds {
    width: 102%;
    margin-bottom: -2px;
    margin-left: -2px;
  }
}

// Body styles
.ern-home {
  font-size: 1.3em;
  font-family: "Gotham-Rounded";
  padding: 0 5%;
  padding-bottom: 106px;
  @media (max-width: 767px) {
    padding: 0 18px 18px 18px;
  }
  h1 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 1.2em;
  }
  h3 {
    margin-bottom: 0.8em;
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }
  h4 {
    font-size: 18px;
    font-weight: bold;
  }
  h1,
  h3,
  h4,
  li {
    font-family: Gotham-Rounded-Medium;
  }
  p {
    line-height: 1.5;
    font-weight: 500;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 25px;
    }
  }
  .ern-border {
    padding-bottom: 3.4em;
    margin-bottom: 3.4em;
    border-bottom: 1px solid #c1c1c1;
    @media (max-width: 767px) {
      padding: 0 10px 28px 10px;
      padding-bottom: 28px;
      margin-bottom: 28px;
    }
  }
  .no-api-border {
    display: none;
  }
  .no-border {
    @media (min-width: 768px) {
      display: none;
    }
  }
  .ern-top-padding {
    padding-top: 1.5em;
  }
  .ern-bottom-margin {
    margin-bottom: 1.5em;
  }
  .ern-top-margin {
    margin-top: 3em;
  }
  &__desktop {
    @media (max-width: 767px) {
      display: none;
    }
    .highlight-btn {
      padding: 0 8px;
      font-size: 20px;
      font-weight: 600;
      color: white;
      background-color: #555;
      margin: -57px 33px 0px 0px;
      opacity: 0.8;
      outline: none;
      &:focus {
        outline: 0;
      }
    }
    #highlight-code-btn {
      font-size: 14px;
    }
    &--btm-margin {
      margin-bottom: 2em;
    }
  }
  &__section-text {
    margin-right: 1em;
    &--right {
      margin-left: 1em;
      @media (max-width: 991px) {
        margin-top: 1.5em;
      }
    }
  }
  &__image {
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      max-width: 600px;
      @media (max-width: 767px) {
        width: 80%;
        height: 80%;
      }
    }
  }
  &__image--sm {
    img {
      max-width: 500px;
    }
  }
  &__section-cli {
    padding: 45px;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    @media (max-width: 767px) {
      padding: 0;
      padding-top: 1em;
      border: none;
    }
    h3 {
      text-align: center;
      margin-top: 0px;
      @media (max-width: 767px) {
        font-size: 18px;
      }
    }
  }
  &__npm-install {
    margin-top: 1.2em;
    color: white;
    padding: 0.5em 0 0.5em 0;
    text-align: center;
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
    background-color: #282c34;
    vertical-align: middle;
    @media (max-width: 767px) {
      margin-top: 10px;
    }
    p {
      font-size: 1.2em;
      display: inline-block;
      line-height: 0;
      @media (max-width: 1150px) {
        font-size: 16px;
      }
      @media (max-width: 820px) {
        font-size: 14px;
      }
      @media (max-width: 460px) {
        font-size: 11px;
        line-height: 1;
      }
    }
  }
  &__module {
    margin-top: 30px;
    padding: 30px;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    min-height: 287px;
    @media (max-width: 1547px) {
      min-height: 314px;
    }
    @media (max-width: 1473px) {
      min-height: 341px;
    }
    @media (max-width: 1282px) {
      min-height: 368px;
    }
    @media (max-width: 1219px) {
      min-height: 395px;
    }
    @media (max-width: 1169px) {
      min-height: 422px;
    }
    @media (max-width: 1219px) {
      min-height: 449px;
    }
    @media (max-width: 1094px) {
      min-height: 476px;
    }
    @media (max-width: 1059px) {
      min-height: 503px;
    }
    @media (max-width: 992px) {
      min-height: 0;
    }
    @media (max-width: 767px) {
      margin-top: 0;
      padding: 0;
      border: none;
      min-height: 0;
    }
  }
  &__header-link {
    color: #333;
    &:hover {
      text-decoration: none;
    }
  }
  &__module-text {
    padding-right: 60px;
    @media (max-width: 767px) {
      padding-left: 0 !important;
      padding-right: 40px;
    }
  }
  &__row {
    margin-top: 1.8em;
  }
  &__col {
    height: 100%;
  }
  &__icon-container {
    position: relative;
    img {
      float: right;
      height: 84px;
      width: auto;
      @media (max-width: 992px) {
        height: 75px;
        width: auto;
      }
    }
  }
}

// mobile styles
.ern-mobile {
  margin-top: 10px;
  h3 {
    font-size: 22px;
    font-family: "Gotham-Rounded-Medium";
    text-align: center;
    padding-left: 18px;
    padding-right: 18px;
    line-height: 30px;
  }
  p {
    font-size: 16px;
    line-height: 25px;
  }
  @media (min-width: 768px) {
    display: none;
  }
  .mob-border {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #c1c1c1;
    @media (max-width: 480px) {
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
  }
  .mob-border-bottom {
    margin-bottom: 28px;
  }
  &__align-center {
    text-align: center;
  }
  &__section {
    padding: 0 18px 5%;
  }
  &__dropdown {
    text-align: center;
  }
  &__text {
    text-align: left;
  }
  #mob-section-1,
  #mob-section-2,
  #mob-section-3 {
    transition: all 0.25s ease-in-out;
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
}
#dropdown-arrow-1,
#dropdown-arrow-2,
#dropdown-arrow-3 {
}
.no-padding {
  @media (max-width: 767px) {
    padding: 0 !important;
  }
}
.ern-top-border {
  margin-top: 5em;
  @media (max-width: 767px) {
    margin-top: 15px;
  }
}
.mobile-docs-nav {
  li {
    margin-top: 0;
    margin-left: 0;
    a:hover {
      color: white !important;
    }
  }
}

// documentation header
.header {
  background: url("/../img/mobile-page/ern_bkgd_nav.png") no-repeat center;
  background-size: cover;
}

// js code highlighting
.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #abb2bf;
  background: #282c34;
}

.hljs-comment,
.hljs-quote {
  color: #5c6370;
  font-style: italic;
}

.hljs-doctag,
.hljs-keyword,
.hljs-formula {
  color: #c678dd;
}

.hljs-section,
.hljs-name,
.hljs-selector-tag,
.hljs-deletion,
.hljs-subst {
  color: #e06c75;
}

.hljs-literal {
  color: #56b6c2;
}

.hljs-string,
.hljs-regexp,
.hljs-addition,
.hljs-attribute,
.hljs-meta-string {
  color: #98c379;
}

.hljs-built_in,
.hljs-class .hljs-title {
  color: #e6c07b;
}

.hljs-attr,
.hljs-variable,
.hljs-template-variable,
.hljs-type,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-number {
  color: #d19a66;
}

.hljs-symbol,
.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-title {
  color: #61aeee;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-link {
  text-decoration: underline;
}
